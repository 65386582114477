/* Estilos base para el botón */
 
  /* Estilos cuando el botón está seleccionado (clase "selected") */
  .button.selected {
    color: var(--white-color);
    background-color: var(--black-color);
  }
  
  /* Estilos cuando el botón no está seleccionado (clase "notSelected") */
  .button.notSelected {
    border: 0.5px solid var(--dark-gray-color);
    background-color: var(--white-color);
    color: var(--black-color) !important;
  }
  
  /* Cambia el color del texto del botón cuando está seleccionado */
  .button.selected {
    color: white;
  }
  
  /* Cambia el color del texto del botón cuando no está seleccionado */
  .button.notSelected {
    color: white;
  }
  