.user{
    padding: 3rem 0;
}
.user-content{
    gap: 4rem;
}
.user-title{
    margin: 1rem 0;
}
.user-img img{
    max-width: 520px;
}
.user-text p{
    margin: 1rem 0;
    opacity: 0.8;
}


@media screen and (min-width: 800px){
    .user-content{
        grid-template-columns: repeat(2, 1fr);
    }
    .user-title{
        margin-top: 0;
    }
    .user-img img{
        margin-left: 0;
    }
}