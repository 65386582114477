/* StatsTableRow.css */

/* Estilo para la fila de la tabla */
tr {
  border-bottom: 1px solid #ddd;
}

/* Estilo para las celdas */
td {
  padding: 8px;
  text-align: center;
}

/* Estilo para las flechas (arrow-up y arrow-down) */
.arrow-up {
  color: green;
  font-size: 16px;
}

.arrow-down {
  color: red;
  font-size: 16px;
}

/* Estilo para el guion (dash) */
.dash {
  color: black;
}

.label-stats-table{
  color: #6B6B6B;
  padding: 12px 0 4px 2px;
}

.diferencias-stats-table{
  display: flex;
  gap: 16px;
  margin-left: 8px;
  margin-bottom: 8px;
}