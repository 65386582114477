/* PlotCounter.css */
.plot-counter {
    padding: 10px;
  }
  
.counter-label {
  font-size: 12px;
  font-weight: 700px;
  color: var(--gray-color);
  background-color: white;
  padding-bottom: 0;
  padding-top: 0;
  border: none;
}

.counter-value {
  padding: 0 10px;
  color: black;
  font-weight: 600;
  font-size: 24px;
  border: none;
}

.tr-counter-stats{
  border: 0px;
}

.negative{
  color: red;
}

.positive{
  color: green;
}