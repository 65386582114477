@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --blue-color: #3b99fc;
  --red-color: #cc3333;
  --dark-red-color: #8d031c;
  --grey-color: #f3f3f3;
  --white-color: #fff;
  --black-color: #010101;
  --light-black-color: #717171;
  --gray-color: #6b6b6b;
  --dark-gray-color: #e2e0dc;
  --light-gray-color: #ededed;
  --transition: all 300ms ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: "Inter", sans-serif;
  color: var(--black-color);
}

p {
  color: var(--gray-color);
  font-family: "Inter", sans-serif;
}
p.fs-17-black {
  font-family: "Inter", sans-serif;
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
}

.col-sm-6 {
  margin-top: 140px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  border: 0.5px solid var(--dark-gray-color);
  border-radius: 12px;
}

/* resets */
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--red-color);
  font-family: "Inter", sans-serif;
}

a:hover {
  color: var(--dark-red-color);
}

a.button.cta_generar_informe:hover {
  color: var(--white-color);
}

button {
  outline: 0;
  border: none;
  font-family: "Inter", sans-serif;
  background-color: transparent;
  cursor: pointer;
}

.primary_button {
  font-family: "Inter", sans-serif;
  color: white;
  background-color: var(--red-color);
  border-radius: 4px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary_button:hover {
  background-color: var(--dark-red-color);
}

img {
  width: 100%;
  display: block;
}

input {
  border: none;
  outline: 0;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
}

input:hover {
  color: var(--black-color);
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

/* backgrounds */
.bg-red {
  background-color: var(--red-color);
}
.bg-black {
  background-color: var(--black-color);
}
.bg-white {
  background-color: var(--white-color);
}

/* text */
.text-black {
  color: var(--black-color);
}
.text-white {
  color: var(--white-color);
}
.text-purple {
  color: var(--purple-color);
}
.text-light-black {
  color: var(--light-black-color);
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-italic {
  font-style: italic;
}

/* font weights */
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

/* font sizes */
.fs-15 {
  font-size: 1.5rem;
}
.fs-16 {
  font-size: 1.6rem;
}
.fs-17 {
  font-size: 16px;
}
.fs-18 {
  font-size: 1.8rem;
}
.fs-20 {
  font-size: 2rem;
}
.fs-22 {
  font-size: 2.2rem;
}
.fs-24 {
  font-size: 2.4rem;
}
.fs-26 {
  font-size: 2.6rem;
}

/* letter spacing */
.ls-1 {
  letter-spacing: 1px;
}
.ls-2 {
  letter-spacing: 2px;
}

.flex {
  display: flex;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-sb {
  justify-content: space-between;
}
.flex-c {
  justify-content: center;
}
.grid {
  display: grid;
}
.container {
  max-width: 1440px;
  margin: 0 auto;
}

/* section title */
.section-title h2 {
  font-weight: 600;
  text-transform: uppercase;
  padding: 18px 0 24px 0;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .col-sm-6 {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .col-sm-6 {
    margin-top: 80px;
    width: 90%;
    padding: 40px 30px;
  }
  h2 {
    font-size: 24px;
  }
}
