.table-container {
    padding: 30px;
    border-radius: 8px;
    width: 48.5%;
    border: 1px solid var(--light-gray-color);
    border-radius: 8px;
}

.conComparar .table-container {
    padding: 30px;
    border-radius: 8px;
    width: 32.4%;
    border: 1px solid var(--light-gray-color);
    border-radius: 8px;
    margin: 0;
}

.sinComparar .table-container {
    width: 49.3%;
}

.header-title-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-title {
    font-size: 18px;
    margin-bottom: 8px;
}

.table-style {
    width: 100%;
    border-collapse: collapse;
}

.ver-mas-button {
    margin-bottom: 4px;
    color: black;
    font-size: 14px;
}



/* Estilos para el overlay del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 10px;
    position: relative;
    max-width: 1080px;
    width: 80vw;
}

.modal-content {
    max-height: 100%;
}

.table-style-modal{
    width: 89%;
    border-collapse: collapse;
    margin: 30px;
    margin: 48px 60px;
}

.table-empty {
    background-color: var(--light-gray-color);
    color: var(--black);
}