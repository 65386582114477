.result-details {
  padding: 6rem 0;
}

.header_modal.informe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-color);
  padding: 0px 30px;
  border-bottom: 1px solid var(--light-gray-color);
  height: 90px;
  margin-bottom: 40px;
}
.back-btn {
  font-size: 24px;
  font-weight: 600;
}

.title_details {
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
  font-family: "Inter", sans-serif;
}

.ReactModal__Content.ReactModal__Content--after-open .content {
  padding-bottom: 40px;
}

.cta_informe {
  color: var(--black-color);
  background-color: var(--white-color);
  border: 0.5px solid var(--dark-gray-color);
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-right: 10px;
  padding: 0 14px;
}
.cta_informe:hover {
  color: var(--white-color);
  background-color: var(--black-color);
  border-color: var(--black-color);
}

.informe-content {
  padding: 0px 30px 30px 30px;
}

.informe-content.noselect {
  text-align: center;
  padding: 80px 30px;
}

.sendReport {
  display: flex;
  margin: 0px 30px 30px 30px;
  border-radius: 4px;
  border: 1px solid var(--light-gray-color);
  padding: 35px 40px;
  background-color: var(--light-gray-color);
}

.back-btn:hover {
  color: var(--purple-color);
}
.back-btn span {
  margin-left: 1rem;
}
.result-details-content {
  padding: 48px 60px;
}
.result-details-img {
  max-height: 600px;
  overflow: hidden;
}
.result-details-img img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.result-details-item {
  margin-bottom: 28px;
}
.result-details-item.description {
  opacity: 0.8;
}

.result-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.ReactModal__Content.ReactModal__Content--after-open {
  position: absolute;
  inset: 40px;
  border: 0px;
  overflow: auto;
  outline: none;
  padding: 20px;
  width: 912px;
  margin: auto;
  border-radius: 10px !important;
  border: transparent !important;
  padding: 0 !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(1.5px);
}

.container_modal {
  max-width: 1440px;
  margin: 0 auto;
}

/* Estilo para el contenedor de los filtros cuando está abierto */
.dropdown_report.open {
  /* Estilos para el contenedor de filtros cuando está abierto */
  /* Por ejemplo, puedes establecer la altura máxima y transiciones aquí */
  max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  overflow: hidden;
  transition: max-height 0.3s ease; /* Agrega una transición suave */
}

/* Estilo para el contenedor de los filtros cuando está cerrado */
.dropdown_report.closed {
  /* Estilos para el contenedor de filtros cuando está cerrado */
  max-height: 0; /* Oculta el contenido al establecer la altura máxima en 0 */
  overflow: hidden;
  transition: max-height 0.3s ease; /* Agrega una transición suave */
}

@media screen and (min-width: 768px) {
  .result-details-content {
    grid-template-columns: 40% 60%;
    gap: 0;
  }
  .result-details-img {
    padding: 0 6rem 0 2rem;
  }
  .result-details-img img {
    max-width: 100%;
  }
}
