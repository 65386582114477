
.report-details-content{
    border-radius: 4px;
    border: 1px solid var(--light-gray-color);
    padding: 35px 40px;
    background-color: var(--white-color);
}

.horizontal-divider {
    margin: 20px 0 0;
}

.report-details-item{
    display: flex;
    gap: 60px;
}

.column-descripcion{
    width: 60%;
}

.column-other-details{
    width: 40%;
}