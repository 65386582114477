
.switch-container {
  display: flex;
  border: 0.5px solid var(--dark-gray-color);
  border-radius: 4px;
  height: 50px;
}

.switch-button.selected {
  background-color: #eaf1eb;
  color: white;
}

.switch-button{
  border: 0px transparent !important;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding: 12px 18px;
  margin: 2px;
  min-width: 70px;
}
.switch-button:active, .switch-button:focus-visible, .switch-button:visited {
  color: var(--white-color);
  background-color: var(--black-color);
  border: 0.5px solid var(--black-color);
  border-radius: 4px;
}

.placeholder-stats{
  font-size: 14px; 
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.switch-select-container{
  display: flex;
  gap: 20px;
}

.select-container{
  width: 400px;
  background-color: white;
  cursor: pointer;
}

.reset{
  background-color: var(--light-gray-color);
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 4px;
  padding: 2px 8px;
  font-size: 12px;
}

@media (min-width: 768px) and (max-width: 1040px){
  .select-container{
    width: 250px;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 768px){
  .search-form{
      flex-direction: column;
  }
}