  /* DatePicker.css */

.date-picker-container {
  position: relative;
  font-family: Arial, sans-serif;
}

.date-picker-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border: 0.5px solid var(--dark-gray-color);
  background-color: white;
  border-radius: 4px;
  padding-left: 20px;
  height: 50px;
  min-width: 245px;
}

.date-picker-dropdown {
  position: absolute;
  top: 85px;
  z-index: 1;
  background: white;
  padding: 8px;
  border: 1px solid var(--light-gray-color);
  border-radius: 8px;
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
}

.placeholder-stats{
  font-size: 14px; 
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.reset{
  background-color: var(--light-gray-color);
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 4px;
  padding: 2px 8px;
  font-size: 12px;
}