table tr td:first-child {
    width: 0.5%;
}

table tr td:nth-child(2) {
    width: 20%;
}

table tr td:nth-child(3) {
    width: 20%;
}

.body_table_oferts td{
  text-align: left;
  padding: 8px 20px;
}

.info_cta_table{
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .info_num_shows{
    margin-top: 28px;
    font-size: 14px;
  }
  
  .cta_generar_informe{
    color: white;
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    width: 200px;
  }

  .cta_generar_informe:hover{
    color: white;
    background-color: var(--dark-red-color);
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    width: 200px;
  }
  
  table{
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 18px;
  }
  
  th{
      width: 700px;
      text-align: left;
      padding: 6px 20px;
      font-size: 14px;
      font-weight: 500;
      color: white;
      background-color: black;
  }
  
  .tr_body{
    background-color: black;
    margin-bottom: 6px;
  }
  
  th{
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-bottom: 10px;
    padding: 8px 20px;
  }
  
  .tr_body{
    background: white; 
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02); 
    border-radius: 4px; border: 1px #EDEDED solid; 
    padding: 24px 26px;
    margin-top: 8px;
  }

  .tr_body_secondary{
    background: var(--light-gray-color); 
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02); 
    border-radius: 4px; border: 1px #EDEDED solid; 
    padding: 24px 26px;
    margin-top: 8px;
  }

  td{
    width: 250px;
    text-align: left;
    padding: 16px 20px;
    font-size: 14px;
    font-weight: 500;
    color: black;
  }