.stats{
    padding: 3rem 0;
    min-height: 75vh;
}
.stats-content{
    gap: 4rem;
}
.stats-title{
    margin: 1rem 0;
}
.stats-img img{
    max-width: 520px;
}
.stats-text p{
    margin: 1.8rem 0;
    opacity: 0.8;
}

@media screen and (min-width: 800px){
    .stats-content{
        grid-template-columns: repeat(2, 1fr);
    }
    .stats-title{
        margin-top: 0;
    }
    .stats-img img{
        margin-left: 0;
    }
}