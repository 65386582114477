.search-form{
    width: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 12px;
}
.search-form-elem{
    padding: 8px 16px;
    border-radius: 4px;
}

.search-form-elem.bg-white.form-input.checkbox{
    padding: 10px 15px;
}

.form-control{
    margin-bottom: 0;
    border: none;
    width: 100%;
    font-size: 16px;
}
.form-control::placeholder{
    color: var(--gray-color);
    margin-top: 0;
    margin-bottom: 0;
}

.css-13cymwt-control {
    text-align: left;
    padding-left: 10px;
}

.search-form-elem.flex.flex-sb.bg-white{
    color: var(--gray-color);
    width: 368px;
    height: 50px;
    padding: 16px;
    border-radius: 4px;
    border: 0.5px solid var(--dark-gray-color);
    font-size: 16px;
    margin: 0 10px;
  }
  
input.search-form-elem.flex.flex-sb.bg-white:active, input.search-form-elem.flex.flex-sb.bg-white:focus{
    border: 0.5px solid var(--red-color);
}

.icon_search{
margin-right: 10px;
}

.label-details{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}
.text-info{
    font-size: 16px;
    text-align: justify;
}
.read-info{
    color: var(--red-color);
    font-size: 16px;
}

@media (min-width: 768px) and (max-width: 1024px){
    .header-content.flex.flex-c.text-center.text-white{
        max-width: 67%;
        margin-left: 20px;
        margin-right: 20px;
    }
    .search-form{
        width: 80%;
    }
    .search-form-elem.flex.flex-sb.bg-white{
        width: 355px;
    }
}

@media (max-width: 768px){
    .search-form{
        flex-direction: column;
    }
}
