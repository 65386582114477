.flex.text-center {
  margin-top: 35px;
  margin-bottom: 45px;
  border: 1px solid var(--light-gray-color);
  border-radius: 8px;
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 30px;
  display: block;
}

.header-content {
  min-height: 50vh;
  flex-direction: column;
}
.header-title {
  font-size: 24px;
  font-weight: 700;
}

h2.header-title.text-capitalize {
  margin-left: 10px;
  text-align: left;
  margin-bottom: 12px;
}
