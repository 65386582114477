.result-details{
    padding: 6rem 0;
}

.header_modal{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    background-color: var(--white-color);
    padding: 0px 60px;
    border-bottom: 1px solid var(--light-gray-color);
    height: 90px;
    position: relative;
}

.icon_cross{
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.title_details {
    font-size: 20px;
    font-weight: 600;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
}

.button.selected, .button.notSelected{
  z-index: 1;
  position: absolute;
  width: 155px;
  height: 42px;
  border-radius: 4px;
  top: 24px;
  left: 700px;
}

.back-btn:hover{
    color: var(--purple-color);
}
.back-btn span{
    margin-left: 1rem;
}
.result-details-content{
    padding: 48px 60px;
}
.result-details-img{
    max-height: 600px;
    overflow: hidden;
}
.result-details-img img{
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.result-details-item{
    margin-bottom: 16px !important;
}

.result-details-item h5{
    margin-bottom: 0px;
}
.result-details-item.description{
    opacity: 0.8;
}

.result-details-item.title .fw-6.fs-24{
    line-height: 1.4em;
}

.details-columns{
    display: flex;
    margin-top: 20px;
    gap: 20px;
}

.column-details{
    flex: 1;
}

.result-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #333;
  }

.ReactModal__Content.ReactModal__Content--after-open{
    position: absolute;
    inset: 40px;
    border: 0px;
    overflow: auto;
    outline: none;
    padding: 20px;
    width: 912px;
    margin: auto;
    border-radius: 10px !important;
    border: transparent !important;
    padding: 0 !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(1.5px);
}

.container_modal{
    max-width: 1440px;
    margin: 0 auto;
}


@media screen and (min-width: 768px){
    .result-details-content{
        grid-template-columns: 40% 60%;
        gap: 0
    }
    .result-details-img{
        padding: 0 6rem 0 2rem;
    }
    .result-details-img img{
        max-width: 100%;
    }
}


