h2{
    font-family: 'Inter', sans-serif;
    color: var(--black-color);
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
}

label{
    color: var(--gray-color);
    display: block;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

input.form-log{
  height: 44px;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 20px;
  border: 0.5px solid var(--dark-gray-color);
  font-size: 16px;
}

input.form-log:active, input.form-log:focus{
    border: 0.5px solid var(--red-color);
}


.login_cta{
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: var(--red-color);
    border-radius: 4px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
}
.login_cta:hover{
    background-color: var(--dark-red-color);
}

.col-sm-6.login{
    margin-bottom: 260px;
}

.login_forgot {
    margin-top: 10px;
    text-align: center;
}

@media (min-width: 768px) and (max-width: 1024px){
    .col-sm-6{
        width: 60%;
    }
    .col-sm-6.login{
        margin-bottom: 300px;
    }
}

@media (max-width: 768px){
    .col-sm-6.login{
        margin-bottom: 0px;
    }
}