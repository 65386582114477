h2{
    color: var(--black-color);
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
}

.col-sm-6.logout{
    margin-bottom: 460px;
}

@media (min-width: 768px) and (max-width: 1024px){
    .col-sm-6.login{
        margin-bottom: 300px;
    }
}

@media (max-width: 768px){
    .col-sm-6.login{
        margin-bottom: 0px;
    }
}