/* SortableColumnHeader.css */

th {
    cursor: pointer;
    white-space: nowrap; /* Evita que el contenido se envuelva a la siguiente línea */
}

  
th:hover {
background-color: var(--dark-red-color); /* Cambia el color de fondo al pasar el mouse */
}

th span {
    margin-right: 5px; /* Ajusta el margen izquierdo para separar el icono del texto */
    vertical-align: middle; /* Alinea verticalmente el icono dentro del span */
    display: inline-block; /* Permite configurar el ancho y alto del span */
    padding-left: 5px; /* Agrega espacio interno alrededor del contenido del span */
    padding-right: 5px; /* Agrega espacio interno alrededor del contenido del span */
    font-weight: bold;
}
  