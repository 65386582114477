/* .search-form */
.search-form {
  width: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 12px;
}

/* .search-form-elem */
.search-form-elem {
  padding: 8px 16px;
  border-radius: 4px;
}

/* .form-control */
.form-control {
  margin-bottom: 0;
  border: none;
  width: 100%;
  font-size: 16px;
}

.form-control::placeholder {
  color: var(--gray-color);
  margin-top: 0;
  margin-bottom: 0;
}

/* .search-form-elem.flex.flex-sb.bg-white */
.search-form-elem.flex.flex-sb.bg-white {
  color: var(--gray-color);
  width: 368px;
  height: 50px;
  padding: 16px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-gray-color);
  font-size: 16px;
  margin: 0 10px;
}

/* input.search-form-elem.flex.flex-sb.bg-white:active, input.search-form-elem.flex.flex-sb.bg-white:focus */
input.search-form-elem.flex.flex-sb.bg-white:active,
input.search-form-elem.flex.flex-sb.bg-white:focus {
  border: 0.5px solid var(--red-color);
  width: 300px;
}

/* .icon_search */
.icon_search {
  margin-right: 10px;
}

/* .css-13cymwt-control */
.css-13cymwt-control {
  color: var(--gray-color);
  height: 50px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-gray-color) !important;
  font-size: 16px;
  margin: 0 10px;
}

/* .css-1xc3v61-indicatorContainer */
.css-1xc3v61-indicatorContainer {
  padding: 12px;
}

/* .container_multichoise */
.container_multichoise {
  width: 100%;
}

/* .select-option */
.select-option {
  text-align: left;
  color: black;
}

.distance-filter {
  width: 500px;
}
.distance-input {
  width: 300px;
}
/* Media Queries */

@media (min-width: 768px) and (max-width: 1024px) {
  .header-content.flex.flex-c.text-center {
    max-width: 67%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .search-form {
    width: 80%;
  }

  .search-form-elem.flex.flex-sb.bg-white {
    width: 355px;
  }
}

@media (max-width: 768px) {
  .search-form {
    flex-direction: column;
  }
}
