.search-form {
  width: auto;
  margin-right: auto;
  display: block !important;
  margin-bottom: 12px;
}

.container.search {
  margin-top: 20px;
  display: flex;
}

.container.search.filter {
  margin-top: 0;
}
.search-form-elem {
  padding: 8px 16px;
  border-radius: 4px;
}

.cta_filtrar {
  color: var(--black-color);
  background-color: var(--white-color);
  border: 0.5px solid var(--dark-gray-color);
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  width: 118px;
  margin-top: 0;
  margin-right: 10px;
  padding: 0 4px;
}
.cta_filtrar:hover {
  color: var(--white-color);
  background-color: var(--black-color);
  border: 0.5px solid var(--black-color);
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  width: 118px;
  margin-top: 0;
  margin-right: 10px;
  padding-right: 4px;
}

.form-control {
  margin-bottom: 0;
  border: none;
  width: 100%;
  font-size: 16px;
}
.form-control::placeholder {
  color: var(--gray-color);
  margin-top: 0;
  margin-bottom: 0;
}

.checkbox-list {
  h5 {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 14px;
  }
  .checkbox-label {
    text-align: left;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    color: var(--gray-color);
    margin-bottom: 14px;
  }
}

input {
  margin-right: 6px;
}

.search-form-elem.flex.flex-sb.bg-white {
  color: var(--gray-color);
  width: 1147px;
  height: 50px;
  padding: 16px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-gray-color);
  font-size: 16px;
  margin: 0 10px;
}

.search-form-elem.flex.flex-sb.bg-white.form-input {
  width: 100%;
}

input.search-form-elem.flex.flex-sb.bg-white:active,
input.search-form-elem.flex.flex-sb.bg-white:focus {
  border: 0.5px solid var(--red-color);
}

.icon_search {
  margin-right: 10px;
}
.icon_filter {
  margin-right: 6px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-content.flex.flex-c {
    max-width: 67%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .search-form {
    width: 80%;
  }
  .search-form-elem.flex.flex-sb.bg-white {
    width: 355px;
  }
}

/* Estilo para el contenedor de los filtros cuando está abierto */
.search-form .dropdown_search.open {
  /* Estilos para el contenedor de filtros cuando está abierto */
  /* Por ejemplo, puedes establecer la altura máxima y transiciones aquí */
  max-height: 500px; /* Ajusta la altura máxima según tus necesidades */
  /* overflow: scroll;*/
  transition: max-height 0.3s ease; /* Agrega una transición suave */
}

/* Estilo para el contenedor de los filtros cuando está cerrado */
.search-form .dropdown_search.closed {
  /* Estilos para el contenedor de filtros cuando está cerrado */
  max-height: 0; /* Oculta el contenido al establecer la altura máxima en 0 */
  overflow: hidden;
  transition: max-height 0.3s ease; /* Agrega una transición suave */
}

@media (max-width: 768px) {
  .search-form {
    flex-direction: column;
  }
}
