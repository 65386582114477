.title-counter-general{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.general-chart{
    background-color: white;
    padding: 10px;
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid var(--light-gray-color);
    border-radius: 8px;
}
.plot-counter{
    width: 30%;
    height: 80px;
}
.table-title.general{
    margin-bottom: 0px;
}