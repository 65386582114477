.stats-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  
  .stats-title {
    margin: 0;
  }
  