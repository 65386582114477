.activeButton {
  color: white;
  background: var(--red-color);
  pointer-events: auto;
}

.inactiveButton {
  color: var(--red-color);
  background: #f9f9f9;
  pointer-events: none;
}

.info_table {
  display: flex;
}

.cta_info_next {
  margin-top: 25px;
  margin-left: 16px;
}

.button.inactiveButton {
  color: rgb(155, 155, 155);
}

.button.activeButton {
  background: var(--red-color);;
}
.button.activeButton:hover {
  background-color: var(--dark-red-color);
}

.button_link,
.button_link:hover {
  color: white;
}

.not-clickable {
  pointer-events: none;
  background-color: var(--gray-color);
  border-radius: 4px;
}

.clickable {
  pointer-events: auto;
  cursor: pointer;
  background-color: var(--red-color);
  border-radius: 4px;
}
