.container-styles {
    display: flex;
    align-items: center;
    height: 24px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 1px;
    margin: 0px;
}

.filler-styles {
    height: 100%;
    border-radius: inherit;
    height: 24px;
}

.modulo-porcentaje{
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}

.porcentaje{
    color: #6B6B6B; 
    font-size: 14px;
}

.value-progressbar{
    color: white;
    margin-left: 8px;
}

.value-progressbar-dark{
    color: var(--light-black-color);
    margin-left: 16px;
}