.form_email {
  display: flex;
  width: 70%;
}
.label_email {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  width: 74%;
  border-radius: 4px;
  margin-right: 8px;
  border: 0.5px solid var(--dark-gray-color);
  font-size: 16px;
  background-color: var(--white-color);
}

.icon_search {
  margin-right: 6px;
}

.send_email {
  font-family: "Inter", sans-serif;
  color: white;
  background-color: var(--red-color);
  border-radius: 4px;
  width: 20%;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1px;
}

.send_email:hover {
  color: white;
  background-color: var(--dark-red-color);
}

.search-form {
  width: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 12px;
}
.search-form-elem {
  padding: 8px 16px;
  border-radius: 4px;
}

.form-control {
  margin-bottom: 0;
  border: none;
  width: 100%;
  font-size: 16px;
}
.form-control::placeholder {
  color: var(--gray-color);
  margin-top: 0;
  margin-bottom: 0;
}

.search-form-elem.flex.flex-sb.bg-white {
  color: var(--gray-color);
  width: 368px;
  height: 50px;
  padding: 16px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-gray-color);
  font-size: 16px;
  margin: 0 10px;
}

input.search-form-elem.flex.flex-sb.bg-white:active,
input.search-form-elem.flex.flex-sb.bg-white:focus {
  border: 0.5px solid var(--red-color);
}

.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.not-clickable {
  pointer-events: none;
  border-radius: 4px;
}

.clickable {
  pointer-events: auto;
  cursor: pointer;
  border-radius: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-content.flex.flex-c.text-center.text-white {
    max-width: 67%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .search-form {
    width: 80%;
  }
  .search-form-elem.flex.flex-sb.bg-white {
    width: 355px;
  }
}

@media (max-width: 768px) {
  .search-form {
    flex-direction: column;
  }
}
