.btn-copy-link,
.btn-copy-link__button {
  display: flex;
  gap: 5px;
}

.btn-copy-link__button {
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 14px;
  margin-top: 8px;
}
