.conComparar{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.sinComparar{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
