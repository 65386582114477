.export-button {
  color: var(--black-color);
  background-color: var(--white-color);
  border: 0.5px solid var(--dark-gray-color);
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  width: 180px;
  margin-top: 0;
  margin-right: 10px;
  padding: 0 4px;
}

.export-button:hover {
  color: var(--white-color);
  background-color: var(--black-color);
  border: 0.5px solid var(--black-color);
}

.export {
  display: flex;
}

.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.not-clickable {
  pointer-events: none;
  border-radius: 4px;
}

.clickable {
  pointer-events: auto;
  cursor: pointer;
  border-radius: 4px;
}