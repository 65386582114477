.stats_form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
    border: 1px solid var(--light-gray-color);
    border-radius: 8px;
    box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.05);
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding: 30px;
    display: block;
    gap: 20px;
}

.multiplechoise-stats{
    width: 40%;
}

.form_dates{
    display: flex;
    gap: 20px;
}

@media (min-width: 768px) and (max-width: 1440px){
    .stats_form{
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (max-width: 768px){
    .search-form{
        flex-direction: column;
    }
}