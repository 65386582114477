.square {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.legend-thread {
  background-color: #f0f0f0;
  display: flex;
  width: auto;
  align-items: center;
}

.bold {
  font-weight: bold;
  margin-right: 5px;
}